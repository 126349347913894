import React from "react"
import Layout from "../components/layout"
import HeroSection from "../components/ui/hero/heroSection"
import Projects from "../components/ui/projects/projects"
import TextSection from "../components/ui/textsection"
import Gallery from "../components/ui/gallery"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import TextOnImage from "../components/ui/textonimage"
import InfoCards from "../components/ui/infocards"
import TestimonialSection from "../components/ui/testimonials"
import ImageSection from "../components/ui/textImage"
import ProductsSection from "../components/ui/products"
import { Button, Container } from "@material-ui/core"
const IndexPage = data => {
  function LayContent(sections, index) {
    switch (sections._type) {
      case "hero": {
        return (
          <HeroSection
            data={sections}
            image={data.data.sanityPage.content[index]}
          />
        )
      }
      case "imageSection": {
        return (
          <ImageSection
            data={sections}
            image={data.data.sanityPage.content[index].image}
          />
        )
      }
      case "projects": {
        return <Projects data={sections} />
      }
      case "textSection": {
        return <TextSection data={sections} />
      }
      case "gallery": {
        return <Gallery data={data.data.sanityPage.content[index]} />
      }
      case "infocards": {
        return <InfoCards data={sections} images={data.data.sanityPage.content[index]} />
      }
      case "textOnImageSection": {
        return <TextOnImage data={sections} image={data.data.sanityPage.content[index].image} imageMobile={data.data.sanityPage.content[index].imageMobile}/>
      }
      case "testimonialSection":{
        return <TestimonialSection data={sections} image={data.data.sanityPage.content[index].testimonial}/>
      }
      case "productsList":{
        return <ProductsSection data={sections} image={data.data.sanityPage.content[index]} siteEmail={data.data.allSanitySiteConfig.edges[0].node.contactemail}/>
      }
      default:{
        return
      }
    }
  }
  return (
    <Layout
      showNav={data.data.sanityPage.shownav}
      menuData={data.data.allSanitySiteConfig.edges[0].node}
      navCta={data.data.sanityPage.navCta?data.data.sanityPage.navCta:null}
    >
      <GatsbySeo
        description={data.data.sanityPage.description}
        title={`${data.data.sanityPage.title}:Karaena Vincent Photography`}
        openGraph={{
          type:"website",
          url:data.location.href,
          description:data.data.sanityPage.description,
          images:[{
            url:data.data.sanityPage.openGraphImage.asset.fixed.src,
            height:data.data.sanityPage.openGraphImage.asset.fixed.height,
            width:data.data.sanityPage.openGraphImage.asset.fixed.width,
            alt:data.data.sanityPage.description
          }]
        }}
      />
      {data.data.sanityPage._rawContent.map((sections, index) => (
        <div key={index}>{LayContent(sections, index)}</div>
      ))}
      {data.data.sanityPage.blogPost&&
      <Container maxWidth="md" style={{marginBottom:"5rem"}}>
      <Button fullWidth href={'/blog'} variant="contained" color="primary">Discover More Posts</Button>
      </Container>
      }
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($id: String!) {
    sanityPage(id: { eq: $id }) {
      description
      title
      shownav
      blogPost
      openGraphImage {
        asset {
          fixed {
            src
            height
            width
          }
        }
      }
      navCta {
        title
        form {
          formTitle
          emailTemplate {
            mailjetTemplateID
          }
          _rawFields(resolveReferences: {maxDepth: 10})
        }
        link
        route {
          slug {
            current
          }
        }
      }
      _rawContent(resolveReferences: { maxDepth: 20 })
      content {
        ... on SanityGallery {
          _key
          _type
          description
          title
          fullWidth: images {
            alt
            caption
            asset {
              fluid(maxWidth:3000) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
          thumbnails: images {
            alt
            caption
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on SanityImageSection {
          _key
          _type
          image {
            alt
            caption
            ...ImageWithPreview
            asset {
              fluid(maxWidth:3000) {
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on SanityHero {
          _key
          _type
          backgroundImage {
            ...Image
          }
          backgroundImageMobile {
            ...Image
          }
        }
        ... on SanityInfocards {
          _key
          _type
          cards {
            _key
            image {
             ...ImageWithPreview
            }
          }
        }
        ... on SanityTextOnImageSection{
          _key
          _type
          image{
           ...ImageWithPreview
          }
          imageMobile{
           ...ImageWithPreview
          }
        }
        ... on SanityProductsList{
          _key
          _type
          _rawProducts(resolveReferences: {maxDepth: 20})
          products{
          image{
            alt
            caption
            asset {
              fluid(maxWidth:1000){
                ...GatsbySanityImageFluid_withWebp_noBase64
              }
            }
            }
          }
        }
        ... on SanityTestimonialSection{
          testimonial{
            companyLogo{
              ...ImageWithPreview
            }
          }
        }
      }
    }
    allSanitySiteConfig {
      edges {
        node {
          id
          contactemail
          contactphone
          title
          mainNavigation {
            slug {
              current
            }
            page {
              ... on SanityPage {
                id
                title
              }
            }
          }
          logo {
            asset {
              url
            }
          }
        }
      }
    }
  }
`
