import React from "react"
import { Typography, Grid, Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Parallax,Background } from "react-parallax"
import TextBlock from "../../../lib/textblock"
import Image from "gatsby-image"
import getFluidImage from "../../../lib/sanitygatsbyimage"

export default function ParallaxSection({ data, image }) {
  const fluid = getFluidImage(data.image, image.asset.fluid)
  const color = data.headingcolor
    ? `rgba(${data.headingcolor.rgb.r},${data.headingcolor.rgb.g},${data.headingcolor.rgb.b},${data.headingcolor.alpha})`
    : "primary"
  const useStyles = makeStyles(theme => ({
    text: {
      position: "absolute",
      top: data.imagePositionTop ? `${data.imagePositionTop}%` : "50%",
      left: data.imagePositionLeft ? `${data.imagePositionLeft}%` : "50%",
      width: data.width ? `${data.width}%` : "50%",
      maxWidth: data.imagePositionLeft
        ? `${100 - parseInt(data.imagePositionLeft)}%`
        : "50%",
      maxHeight: data.imagePositionTop
        ? `${100 - parseInt(data.imagePositionTop)}%`
        : "50%",
      height: data.height ? `${data.height}%` : "fit-content",
      backgroundColor:
        data.textBG &&
        !data.imageBG &&
        `rgba(${data.textBG.rgb.r},${data.textBG.rgb.g},${data.textBG.rgb.b},${data.textBG.alpha})`,
      padding: theme.spacing(10),
    },
    parallaxContainer: {
      position: "relative",
      overflow: "hidden",
      width: "100%",
      minHeight: "100vh",
      height: "fit-content",
      padding: "2rem 0",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    imageContainer: {
      width: "100%",
      height: "100vh",
      position: "relative",
    },
  }))
  const classes = useStyles()
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12}>
          {data.heading && (
            <>
              <Typography color={color} variant="h2" align="center">
                {data.heading}
              </Typography>
              {data.label && (
                <Typography color={color} variant="h4" align="center">
                  {data.label}
                </Typography>
              )}
            </>
          )}
        </Grid>
        <div className={classes.parallaxContainer}>
          <Parallax
            strength={1000}
          >
            <Background>
              <Image
                backgroundColor
                fluid={fluid}
                alt={data.image.alt}
                style={{ height: "100%" }}
              />
            </Background>

          <div className={classes.text}>
            <TextBlock text={data.text} />
          </div>
          </Parallax>
        </div>
      </Grid>
    </Container>
  )
}
