import React from 'react'
import ParallaxSection from './parallax'
import ImageText from './textimage'
import { useTheme, useMediaQuery } from '@material-ui/core'

export default function ImageSection({data,image}){
 
    const theme=useTheme()
    const phone=useMediaQuery(theme.breakpoints.down('xs'))
    if(data.imagePosition==="parallax"&&!phone){
        return <ParallaxSection data={data} image={image}/>
    }
    else{
        return<ImageText data={data} image={image} removecolors={phone}/>
    }
}